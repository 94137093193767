import React, { useState } from 'react';
import { PlusCircle, Upload, Download, Search, User } from 'lucide-react';

// ProspectCard Component
const ProspectCard = ({ prospect }) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-md border border-gray-200 mb-4">
      <div className="flex items-center space-x-3 mb-3">
        <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
          <User className="w-6 h-6 text-blue-600" />
        </div>
        <div>
          <h3 className="font-semibold text-lg text-gray-800">{prospect.name}</h3>
          <p className="text-blue-600">{prospect.email}</p>
        </div>
      </div>
      <p className="text-gray-600 text-sm">{prospect.company_url}</p>
      <span className="inline-block bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded mt-2">
        {prospect.field}
      </span>
    </div>
  );
};

// KanbanCard Component
const KanbanCard = ({ prospect, onDragStart }) => {
  return (
    <div
      draggable
      onDragStart={(e) => onDragStart(e, prospect)}
      className="bg-white p-3 rounded shadow cursor-move hover:shadow-md transition-shadow"
    >
      <div className="flex items-center space-x-3">
        <div className="w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center flex-shrink-0">
          <User className="w-4 h-4 text-blue-600" />
        </div>
        <div className="min-w-0">
          <p className="font-medium truncate">{prospect.name}</p>
          <p className="text-sm text-gray-600 truncate">{prospect.company_url}</p>
        </div>
      </div>
    </div>
  );
};

// AddProspectModal Component
const AddProspectModal = ({ isOpen, onClose, onAdd }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company_url: '',
    field: ''
  });

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    onAdd({ ...formData, status: 'New Lead' });
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg w-96">
        <h2 className="text-xl font-semibold mb-4">Add New Prospect</h2>
        <form onSubmit={handleSubmit}>
          <div className="space-y-4">
            <input
              type="text"
              placeholder="Name"
              className="w-full p-2 border rounded"
              onChange={e => setFormData({...formData, name: e.target.value})}
            />
            <input
              type="email"
              placeholder="Email"
              className="w-full p-2 border rounded"
              onChange={e => setFormData({...formData, email: e.target.value})}
            />
            <input
              type="url"
              placeholder="Company URL"
              className="w-full p-2 border rounded"
              onChange={e => setFormData({...formData, company_url: e.target.value})}
            />
            <input
              type="text"
              placeholder="Field"
              className="w-full p-2 border rounded"
              onChange={e => setFormData({...formData, field: e.target.value})}
            />
          </div>
          <div className="mt-4 flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Add Prospect
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// KanbanBoard Component
const KanbanBoard = ({ prospects, setProspects }) => {
  const statuses = ['New Lead', 'Contacted', 'Meeting Scheduled', 'Proposal', 'Closed'];

  const handleDragStart = (e, prospect) => {
    e.dataTransfer.setData('prospectId', prospect.email);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetStatus) => {
    e.preventDefault();
    const prospectId = e.dataTransfer.getData('prospectId');
    
    setProspects(prospects.map(p => {
      if (p.email === prospectId) {
        return { ...p, status: targetStatus };
      }
      return p;
    }));
  };

  return (
    <div className="flex gap-4 overflow-x-auto p-4">
      {statuses.map(status => (
        <div 
          key={status} 
          className="flex-shrink-0 w-72"
          onDragOver={handleDragOver}
          onDrop={(e) => handleDrop(e, status)}
        >
          <div className="bg-gray-100 p-4 rounded-lg">
            <div className="flex items-center justify-between mb-4">
              <h3 className="font-semibold text-gray-700">{status}</h3>
              <span className="bg-gray-200 text-gray-600 text-sm px-2 py-1 rounded">
                {prospects.filter(p => p.status === status).length}
              </span>
            </div>
            <div className="space-y-2 min-h-[200px]">
              {prospects
                .filter(prospect => prospect.status === status)
                .map(prospect => (
                  <KanbanCard 
                    key={prospect.email} 
                    prospect={prospect}
                    onDragStart={handleDragStart}
                  />
                ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

// Main CRM Component
const CRM = () => {
  const [prospects, setProspects] = useState([]);
  const [view, setView] = useState('cards');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const newProspects = JSON.parse(e.target.result);
          setProspects([...prospects, ...newProspects]);
        } catch (error) {
          alert('Invalid file format');
        }
      };
      reader.readAsText(file);
    }
  };

  return (
    <div className="min-h-screen bg-white-50">
      {/* Header */}
      <header className="bg-white border-b">
        <div className="max-w-7xl mx-auto px-4 py-4">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold text-blue-600">Echidna</h1>
            <div className="flex items-center space-x-4">
              <button
                onClick={() => setView('cards')}
                className={`px-4 py-2 rounded ${
                  view === 'cards' ? 'bg-blue-600 text-white' : 'text-gray-600'
                }`}
              >
                Cards
              </button>
              <button
                onClick={() => setView('kanban')}
                className={`px-4 py-2 rounded ${
                  view === 'kanban' ? 'bg-blue-600 text-white' : 'text-gray-600'
                }`}
              >
                Kanban
              </button>
            </div>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 py-8">
        {/* Actions Bar */}
        <div className="mb-8 flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <button
              onClick={() => setIsModalOpen(true)}
              className="flex items-center px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              <PlusCircle className="w-4 h-4 mr-2" />
              Add Prospect
            </button>
            <label className="flex items-center px-4 py-2 bg-white border border-gray-300 rounded hover:bg-gray-50 cursor-pointer">
              <Upload className="w-4 h-4 mr-2" />
              Upload CSV
              <input
                type="file"
                className="hidden"
                accept=".csv,.json"
                onChange={handleFileUpload}
              />
            </label>
            <button className="flex items-center px-4 py-2 bg-white border border-gray-300 rounded hover:bg-gray-50">
              <Download className="w-4 h-4 mr-2" />
              Export
            </button>
          </div>
          <div className="relative">
            <Search className="w-5 h-5 absolute left-3 top-2.5 text-gray-400" />
            <input
              type="text"
              placeholder="Search prospects..."
              className="pl-10 pr-4 py-2 border rounded-lg w-64"
            />
          </div>
        </div>

        {/* Content View */}
        {view === 'cards' ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {prospects.map(prospect => (
              <ProspectCard key={prospect.email} prospect={prospect} />
            ))}
          </div>
        ) : (
          <KanbanBoard 
            prospects={prospects} 
            setProspects={setProspects}
          />
        )}

        {/* Add Prospect Modal */}
        <AddProspectModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onAdd={(newProspect) => {
            setProspects([...prospects, newProspect]);
          }}
        />
      </main>
    </div>
  );
};

export default CRM;